@import '../base/variables';

section.media {
    display: flex;
    justify-content: center;

    &>.foreground {
        z-index: 2;

        h2 {
            margin-top: 60px;
        }
    }

    p {
        line-height: 1.4em;
    }

    p:not(:last-of-type) {
        margin-bottom: 20px;
    }

    .video {
        position: relative;
    }

    .video .background {
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
        position: absolute;
        background-color: $gray;
    }

    .video:hover {
        background-color: #ccc;
        cursor: pointer;
    }

    .video .overlay {
        display: flex;
        position: absolute;
        justify-content: center;
        align-items: center;
        height: 100%;
        flex-direction: column;
        width: 100%;
        color: #ecf0f1;
        background: transparent;
        opacity: 0;
        transition: all 0.2s ease;
    }

    .video:hover .overlay {
        opacity: 1;
        background: rgba(0, 0, 0, 0.4);
    }

    .audio .overlay {
        opacity: 1;
    }

    .video .background {
        filter: grayscale(0);
        transition: all 0.2s ease;
    }

    .video:hover .background {
        filter: grayscale(80%);
    }

    .video h3 {
        font-weight: 600;
        margin-bottom: 10px;
        font-size: 1em;
    }

    .video p {
        font-weight: 200;
        font-size: 0.8em;
        text-align: center;
    }

    .video p:not(:last-of-type) {
        margin-bottom: 0px;
    }

    .grid {
        margin-top: 30px;
        display: grid;
        grid-auto-rows: 200px;
        grid-column-gap: 10px;
        grid-row-gap: 10px;
    }

    .buttonsWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 30px;
    }

    .archive-item {
        font-size: 0.8em;
        display: inline-block;
        &:not(:last-of-type) {
            margin-bottom: 10px;
        }
    }
}

@media screen and (min-width: $break-large) {

    section.media>.foreground {
        width: $width-large;
        padding: 0 0 150px 0;
    }
}

@media screen and (max-width: $break-large) and (min-width: $break-medium) {

    section.media>.foreground {
        width: $width-medium;
        padding: 0 0 150px 0;
    }
}

@media screen and (max-width: $break-medium) and (min-width: $break-small) {

    section.media>.foreground {
        width: $width-small;
        padding: 0 0 150px 0;
    }
}

@media screen and (max-width: $break-small) and (min-width: $break-xsmall) {

    section.media>.foreground {
        width: $width-xsmall;
        padding: 20px;
    }
}

@media screen and (max-width: $break-xsmall) {

    section.media>.foreground {
        padding: 20px;
        width: 100%;
    }
}

@media screen and (min-width: $break-small) {
    section.media .grid {
        grid-template-columns: repeat(3, auto);
    }

    section.media .video .overlay {
        padding: 50px;
    }
}

@media screen and (max-width: $break-small) {
    section.media .grid {
        grid-template-columns: repeat(2, auto);
    }

    section.media .video .overlay {
        padding: 20px;
    }
}