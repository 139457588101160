@import '../base/_variables';

footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background: $black;
    color: $gray;
    font-size: 10px;
    height: 50px;

    .foreground {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 980px;

        a,
        a:active,
        a:hover {
            color: $red;
        }
    }
}