.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 20;

    .background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #0a1519;
        opacity: 0.8;
    }

    .foreground {
        width: 95vw;
        height: 95vh;

        &.smallModal {
            height: auto;
            width: auto;
        }

        background: #f0f0f0;
        border: #ccc;
        z-index: 1;
        display: flex;
        flex-direction: column;

        .foreground-top {
            min-height: 60px;
            padding: 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .closeButton {
                padding: 10px;
                &:hover {
                    cursor: pointer;
                }
            }
        }

        .foreground-bottom {
            min-height: 20px;
        }

        .foreground-middle {
            padding: 0 20px;
            flex: 1;

            iframe {
                width: 100%;
                height: 100%;
            }
        }
    }
}

@media screen and (min-width: $break-small) {
    .modal .foreground.smallModal {
        min-width: 500px;
    }
}

@media screen and (max-width: $break-small) {
    .modal .foreground.smallModal {
        width: 100%;
    }
}