@import '../base/_variables.scss';

button,
.buttonLink {
    background: $red;
    color: $white;
    border: 0;
    display: inline-block;
    text-transform: uppercase;
    cursor: pointer;
    outline: none;

    &:hover {
        background: darken($red, 5%);
        color: darken($white, 5%);
    }

    &.transparent {
        background: transparent;
        border: 3px solid #7f8c8d;
    }
}

@media screen and (min-width: $break-small) {

    button,
    .buttonLink {
        &.btn-small {
            padding: 10px 30px;
            font-size: 0.8em;
        }

        &.btn-large {
            padding-left: 40px;
            padding-right: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1em;
        }
    }
}

@media screen and (max-width: $break-small) {

    button,
    .buttonLink {
        &.btn-small {
            padding: 5px 15px;
            font-size: 0.6em;
        }

        &.btn-large {
            padding-left: 30px;
            padding-right: 30px;
            height: 36px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 0.8em;
        }

    }
}