$white: #ecf0f1;
$red: #c0392b;
$black: #1a1a1a;
$gray: #666666;

$primary-font: 'Open Sans', sans-serif;
$secondary-font: 'Merriweather Sans', sans-serif;

$break-large: 1150px;
$break-medium: 960px;
$break-small: 650px;
$break-xsmall: 500px;

$width-large: 980px;
$width-medium: 800px;
$width-small: 600px;
$width-xsmall: 450px;