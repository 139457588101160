@import './variables';

* {
    box-sizing: border-box;
    outline: none;
}

body {
    font-family: $primary-font;
    margin: 0;
    padding: 0;
    background: #FAF9F4;
    color: $black;
}

strong {
    font-weight: bold;
}

p,
td {
    font-weight: 400;
}

h2 {
    font-family: $secondary-font;
    text-transform: uppercase;
    margin-bottom: 20px;
    position: relative;
    margin-left: 80px;
    font-weight: 600;

    &::before {
        content: " ";
        background: $red;
        width: 60px;
        height: 4px;
        position: absolute;
        top: 9px;
        left: -80px;
    }

    &.white {
        color: $white;
        font-weight: 500;
    }
}

h3 {
    font-weight: 600;
}

.disclaimer {
    font-size: 0.7em;
    text-align: center;
    padding: 50px 0;
    color: #ddd;
    a, a:hover, a:visited, a:active {
        color: #ddd;
        text-decoration: underline;
    }
}

@media screen and (min-width: $break-small) {
    h2 {
        font-size: 1.2em;
    }

    h3 {
        font-size: 1.3em;
    }

    p,
    td {
        line-height: 1.5em;
        font-size: 0.9em;
    }
}

@media screen and (max-width: $break-small) {
    h2 {
        font-size: 1em;
    }

    h3 {
        font-size: 1em;
    }

    p,
    td {
        line-height: 1.2em;
        font-size: 0.7em;
    }
}