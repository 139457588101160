.logo {
    width: 200px;
    height: 50px;
    background-image: url('/images/logo.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left center;

    img {
        width: 100%;
    }
}