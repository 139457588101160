section.article {
    display: flex;
    justify-content: center;

    .foreground {
        padding: 60px 0;
        z-index: 2;

        h2 {
            margin-top: 60px;
        }

        h3 {
            margin-top: 40px;
            text-transform: uppercase;
            font-size: 1.2em;
        }

        p {
            line-height: 1.4em;

            &:not(:last-of-type) {
                margin-bottom: 20px;
            }
        }
    }
}

section.article.presents {
    font-size: 36px;
    // background: #c0392b;
    // color: #ffffff;
    position: relative;

    .background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        background: url('/images/pattern.png');
        opacity: 0.15;
        background-size: 130px;
    }

    .foreground {
        position: relative;
        z-index: 4;

        text-shadow: 4px 4px 8px rgba(0, 0, 0, 0.04);

        iframe {
            width: 100%;
            margin-bottom: 30px;
        }

        p {
            margin-bottom: 40px;
            font-size: 18px;
            font-weight: 100;
            text-align: justify;

            &.italic {
                font-style: italic;
            }
        }

        .flyers-container {
            width: 100%;
            display: flex;
            margin-bottom: 30px;

            .flyer:not(:first-child) {
                margin-left: 10px;
            }

            .flyer:not(:last-child) {
                margin-right: 10px;
            }
            
            img {
                width: 100%;
                box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
            }
        }

        .center {
            text-align: center;
        }

        .image-container {
            margin-top: 80px;
            margin-bottom: 80px;

            img {
                border-radius: 40px;
                width: 100%;
                box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
                max-width: 400px;
            }
        }

        small {
            font-size: 14px;
        }

        strong {
            font-weight: 600;
            font-size: 22px;
        }

        .red {
            color: #c0392b;
        }

        h3 {
            font-size: 28px;
            margin-top: 80px;
            margin-bottom: 80px;
            text-transform: none;
            text-align: center;
        }

        h4 {
            text-align: center;
            font-size: 32px;
            margin-top: 80px;
            margin-bottom: 80px;
            text-align: center;
        }
    }
}

@media screen and (min-width: $break-large) {

    section.article .foreground {
        width: $width-large;
    }
}

@media screen and (max-width: $break-large) and (min-width: $break-medium) {

    section.article .foreground {
        width: $width-medium;
    }
}

@media screen and (max-width: $break-medium) and (min-width: $break-small) {

    section.article .foreground {
        width: $width-small;
    }
}

@media screen and (max-width: $break-small) and (min-width: $break-xsmall) {

    .flyers-container {
        flex-direction: column;

        .flyer {
            margin-left: 0 !important;
            margin-right: 0 !important;
            margin-bottom: 10px;
        }
    }

    section.article .foreground {
        width: $width-xsmall;
    }
}

@media screen and (max-width: $break-xsmall) {

    .flyers-container {
        flex-direction: column;

        .flyer {
            margin-left: 0 !important;
            margin-right: 0 !important;
            margin-bottom: 10px;
        }
    }

    section.article .foreground {
        padding-left: 20px;
        padding-right: 20px;
        width: 100%;
    }

}