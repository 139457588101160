@import '../base/variables';

section.events {
    display: flex;
    justify-content: center;
    position: relative;
    background: #6b7071;

    .background {
        background-position: center;
        background-size: cover;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        opacity: 0.65;
        mix-blend-mode: multiply;
    }

    .foreground {
        z-index: 2;
        padding: 80px 0;
    }

    .foreground h2 {
        margin-bottom: 40px;
    }

    .foreground .description h3,
    .foreground .description p {
        color: $white;
    }

    .date {
        font-weight: 400;
    }

    .listItem {
        display: flex;
    }

    .listItem:not(:last-of-type) {
        margin-bottom: 30px;
    }

    .listItem .description {
        background: rgba(0, 0, 0, 0.4);
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        padding: 0 20px;
    }

    .listItem:hover {
        cursor: pointer;
    }

    .listItem .description:hover {
        background: rgba(0, 0, 0, 0.45);
    }
}

@media screen and (min-width: $break-large) {

    section.events .background {
        background-image: url('/images/events-background-2500w.webp');
    }

    section.events .foreground {
        width: $width-large;
    }
}

@media screen and (max-width: $break-large) and (min-width: $break-medium) {

    section.events .background {
        background-image: url('/images/events-background-2500w.webp');
    }

    section.events .foreground {
        width: $width-medium;
    }
}

@media screen and (max-width: $break-medium) and (min-width: $break-small) {

    section.events .background {
        background-image: url('/images/events-background-1000w.webp');
    }

    section.events .foreground {
        width: $width-small;
    }
}

@media screen and (max-width: $break-small) and (min-width: $break-xsmall) {

    section.events .background {
        background-image: url('/images/events-background-1000w.webp');
    }

    section.events .foreground {
        width: $width-xsmall;
    }
}

@media screen and (max-width: $break-xsmall) {

    section.events .background {
        background-image: url('/images/events-background-700w.webp');
    }

    section.events .foreground {
        padding-left: 20px;
        padding-right: 20px;
        width: 100%;
    }

}

@media screen and (min-width: $break-small) {
    section.events .foreground .date {
        width: 80px;
        height: 80px;
        font-size: 1.1em;
    }
}

@media screen and (max-width: $break-small) {
    section.events .foreground .date {
        font-size: 0.8em;
        width: 60px;
        height: 60px;
    }
}