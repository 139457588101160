section.archive {
    width: 100%;
    padding: 50px;
    .archive-item:not(:last-of-type) {
        // display: inline-block;
        // margin: 10px;
        &:hover {
            cursor: pointer;
        }
    }
}