@import './base/_reset';
@import './base/_typography';
@import './base/_base';

@import './components/_audio-player';
@import './components/_buttons';
@import './components/_date';
@import './components/_logo';
@import './components/_menu';
@import './components/_modal';

@import './sections/_address';
@import './sections/_archive';
@import './sections/_article';
@import './sections/_events';
@import './sections/_footer';
@import './sections/_header';
@import './sections/_media';
@import './sections/_welcome';

section.important {
    display: flex;
    justify-content: center;

    .foreground {
        display: flex;
        padding: 150px 0;
        flex-direction: column;

        p {
            font-size: 1rem;
            text-indent: 4rem;
            margin-bottom: 2rem;
        }

        ul {
            margin-top: 2rem;
            margin-bottom: 2rem;
            padding-left: 4rem;
        }

        ul li {
            font-size: 1rem;
            margin-bottom: 2rem;
            list-style-type: disc;
        }
    }
}

@media screen and (min-width: $break-large) {
    section.important .foreground {
        width: $width-large;
    }
}

@media screen and (max-width: $break-large) and (min-width: $break-medium) {

    section.important .foreground {
        width: $width-medium;
    }
}

@media screen and (max-width: $break-medium) and (min-width: $break-small) {

    section.important .foreground {
        width: $width-small;
    }
}

@media screen and (max-width: $break-small) and (min-width: $break-xsmall) {

    section.important .foreground {
        width: $width-xsmall;
    }
}

@media screen and (max-width: $break-xsmall) {

    section.important .foreground {
        padding-left: 20px;
        padding-right: 20px;
        width: 100%;
    }
}