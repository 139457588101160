@font-face {
    font-family: 'Merriweather Sans';
    font-style: normal;
    font-weight: 300;
    src: url('/fonts/MerriweatherSans-Light.ttf')
}

@font-face {
    font-family: 'Merriweather Sans';
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/MerriweatherSans-Regular.ttf')
}

@font-face {
    font-family: 'Merriweather Sans';
    font-style: normal;
    font-weight: 700;
    src: url('/fonts/MerriweatherSans-Bold.ttf')
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    src: url('/fonts/OpenSans-Light.ttf')
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/OpenSans-Regular.ttf')
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    src: url('/fonts/OpenSans-Bold.ttf')
}
