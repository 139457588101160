@import '../base/_variables.scss';

@media screen and (min-width: $break-small) {
    header .foreground-top nav {
        &.largeScreen {
            display: flex;
            align-items: center;

            a {
                color: $white;
                padding: 0 10px;

                &:hover {
                    color: darken($white, 5%);
                    cursor: pointer;
                }
            }
        }

        &.smallScreen {
            display: none;
        }
    }
}

@media screen and (max-width: $break-small) {
    header .foreground-top nav {
        &.largeScreen {
            display: none;
        }

        &.smallScreen {
            display: flex;
        }
    }
}

header .foreground-top nav.smallScreen {
    position: relative;

    button.toggle {
        background: transparent;
        font-size: 2em;
    }

    .links {
        position: fixed;
        right: 0;
        top: 0;
        display: flex;
        flex-direction: column;
        width: 60vw;
        height: 100vh;
        background: lighten(#FAF9F4, 3%);
        z-index: 9;
        box-shadow: -3px 0px 3px rgba(0, 0, 0, 0.08);

        .toggleWrapper {
            display: flex;
            justify-content: flex-end;
            padding: 5px;

            button {
                right: 5px;
                top: 5px;
                height: 30px;
                width: 30px;
                padding: 0;
            }
        }

        a {
            color: $black;
            padding: 15px;
            font-size: 0.8em;

            &:hover {
                background: darken(#FAF9F4, 3%);
            }

            &:hover,
            &:active,
            &:visited {
                color: $black;
            }
        }
    }
}