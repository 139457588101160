@import '../base/variables';

section.address {
    display: flex;
    justify-content: center;
    position: relative;
    background: $black;

    .background {
        background-position: center;
        background-size: cover;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        opacity: 0.3;
        mix-blend-mode: screen;
        filter: contrast(60%) brightness(100%) saturate(50%);
    }

    .foreground {
        padding: 150px 0;
        z-index: 2;
        color: $white;

        .row {
            display: flex;
            padding-top: 60px;
        }

        .left {
            flex: 2;
            padding-right: 30px;
        }

        .right {
            flex: 1;
            padding-left: 30px;

            i {
                min-width: 40px;
            }
        }

        .right .right p:not(:last-of-type) {
            margin-bottom: 30px;
        }

        p {
            margin-bottom: 10px;
        }
    }

    td {
        padding: 10px;
        color: $white;
    }
}


@media screen and (min-width: $break-large) {

    section.address .background {
        background-image: url('/images/contact-background-1600w.webp');
    }

    section.address .foreground {
        width: $width-large;
    }
}

@media screen and (max-width: $break-large) and (min-width: $break-medium) {

    section.address .background {
        background-image: url('/images/contact-background-1600w.webp');
    }

    section.address .foreground {
        width: $width-medium;
    }
}

@media screen and (max-width: $break-medium) and (min-width: $break-small) {

    section.address .background {
        background-image: url('/images/contact-background-1000w.webp');
    }

    section.address .foreground {
        width: $width-small;
    }
}

@media screen and (max-width: $break-small) and (min-width: $break-xsmall) {

    section.address .background {
        background-image: url('/images/contact-background-1000w.webp');
    }

    section.address .foreground {
        padding: 60px 0;
        width: $width-xsmall;

        .row {
            flex-direction: column;

            .right {
                padding-left: 0;
                padding-top: 60px;
            }
        }
    }
}

@media screen and (max-width: $break-xsmall) {

    section.address .background {
        background-image: url('/images/contact-background-700w.webp');
    }

    section.address .foreground {
        padding: 60px 20px;
        width: 100%;

        .row {
            flex-direction: column;

            .right {
                padding-left: 0;
                padding-top: 60px;
            }
        }
    }

}