@import '../base/variables';

.date {
    background: $red;
    color: $white;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 0.8em;
    font-weight: 400;
}