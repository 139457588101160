section.welcome {
    display: flex;
    justify-content: center;

    .foreground {
        display: flex;
        padding: 150px 0;
        flex-direction: column;
    }

    .foreground .row {
        display: flex;
        align-items: center;
    }

    .foreground .left {
        flex: 2;
        padding-right: 30px;
    }

    .foreground .right {
        flex: 1;
        padding-left: 30px;
    }

    .foreground .right img {
        width: 100%;
    }

    .buttonLink {
        margin-top: 20px;
    }
}

@media screen and (min-width: $break-large) {
    section.welcome .foreground {
        width: $width-large;
    }
}

@media screen and (max-width: $break-large) and (min-width: $break-medium) {

    section.welcome .foreground {
        width: $width-medium;
    }
}

@media screen and (max-width: $break-medium) and (min-width: $break-small) {

    section.welcome .foreground {
        width: $width-small;
    }
}

@media screen and (max-width: $break-small) and (min-width: $break-xsmall) {

    section.welcome .foreground {
        width: $width-xsmall;
    }
}

@media screen and (max-width: $break-xsmall) {

    section.welcome .foreground {
        padding-left: 20px;
        padding-right: 20px;
        width: 100%;
    }
}